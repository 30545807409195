import React from 'react';
import { useState, useEffect } from 'react';
import { Spinner } from 'rendition/dist_esm5/components/Spinner';
import { compareVersions } from 'compare-versions';
import data from './data/success.json';

import {
  Aligner,
  Main,
  StyledDiv,
} from './sections/etcher/SuccessBannerUtils';

import {
  HorizontalView,
  OutdatedVersionBanner,
  VerticalView
} from './templates/etcher-success-banner';

// function getPreviewCompatibleImage({ image, childImageSharp }) {
//   if (!!image && !!image.childImageSharp) {
//     return image.childImageSharp.fluid.src;
//   }
//   if (!!childImageSharp) {
//     return childImageSharp.fluid.src;
//   }
//   if (!!image && typeof image === 'string') return image;

//   return null;
// }

// Main view, randomly displays one of the test cases. See data/etcher/etcher-success-banner
const Banner = props => {
  const [version, setVersion] = useState(undefined);
  const [borderTop, setBorderTop] = useState('true');
  const [darkBackground, setDarkBackground] = useState('false');

  useEffect(
    () => {
      const searchParams = new URL(window.location.href).searchParams;
      if (searchParams.get('etcher-version')) {
        setVersion(searchParams.get('etcher-version'));
      }
      if (searchParams.get('borderTop')) {
        setBorderTop(searchParams.get('borderTop'));
      }
      if (searchParams.get('borderTop')) {
        setDarkBackground(searchParams.get('darkBackground'));
      }
    },
    [setVersion, setBorderTop, setDarkBackground]
  );

  if (version) {
    const legacy = compareVersions(version, '1.4.4') < 1;
    const showOutdatedVersionBanner = compareVersions(version, '1.4.8') < 1;
    const verticalViewEnabled = compareVersions(version, '1.5.110') > -1;

    const className = legacy
      ? 'legacy-background-color'
      : 'new-background-color';

    if (showOutdatedVersionBanner) {
      return <OutdatedVersionBanner className={className} />;
    }

    // if (!banners.length) {
    //   return (
    //     <StyledDiv>
    //       <Main>
    //         <Aligner />
    //       </Main>
    //     </StyledDiv>
    //   );
    // }

    // defaulting to first active case, will revisit this when implementing a
    // random selector between active cases
    const activeBanner = props.data;
    const horizontalView = activeBanner.layout !== 'vertical';
    if (!verticalViewEnabled || horizontalView) {
      return (
        <HorizontalView
          darkBackground={darkBackground}
          activeBanner={activeBanner}
          borderTop={borderTop}
        />
      );
    }
    return (
      <VerticalView
        borderTop={borderTop}
        lead={activeBanner.lead}
        text={activeBanner.text}
        image={activeBanner.image}
        cta={activeBanner.cta.label}
        redirect={activeBanner.redirect}
      />
    );
  }
  return (
    <StyledDiv>
      <Main
        style={{
          backgroundColor: darkBackground === 'true' ? '#414449' : '#4d5057',
        }}
      >
        <Aligner>
          <Spinner />
        </Aligner>
      </Main>
    </StyledDiv>
  );
};

const Page = props => {
  return (
    <Banner data={data} />
  );
};

export default Page;