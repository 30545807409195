/* eslint-disable react/jsx-pascal-case */

import React from "react";
import { Button, Flex, Heading, Txt, Link } from "rendition";
import EtcherProReadyToShip from "./images/EtcherPro.avif";

export const EtcherPro = () => {

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      bg='#414449'
      height='100vh'
      p={3}
    >
      <Heading.h5
        color='lightGrey'
        style={{
          textAlign: "center",
        }}
      >
        Want to flash multiple drives at high speeds?
      </Heading.h5>

      <img
        alt='EtcherPro ready to ship'
        style={{
          maxHeight: "250px",
          maxWidth: "250px",
          borderRadius: "15px",
          marginTop: "15px",
          marginBottom: "15px",
        }}
        src={EtcherProReadyToShip}
      />
      <Txt
        color='#c9cacd'
        style={{
          textAlign: "center",
        }}
        mt={1}
        mb={3}
        maxWidth='400px'
      >
        Write to 16 cards or usb disks at once at extreme speeds, using the same
        interface you know and love.
      </Txt>
      <Link
        blank
        href='https://store.balena.io/products/etcher-pro'
      >
        <Button primary>Get EtcherPro</Button>
      </Link>
    </Flex>
  );
};
